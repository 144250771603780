import { capitalize } from '@/utils/helpers';
const cityData = ['bangalore', 'jaipur', 'pune', 'mumbai', 'chennai', 'hyderabad', 'udaipur'];

export interface SubMenuItem {
  title: string;
  link: string;
}

export interface MenuItem {
  title: string;
  link?: string;
  subMenu?: SubMenuItem[];
}
export const menuData: MenuItem[] = [
  {
    title: 'Indian Call Girls',
    link: '/escorts',
    subMenu: cityData.map(city => ({
      title: `Call Girls In ${capitalize(city)}`,
      link: `/escorts/${city}`,
    })),
  },
  {
    title: 'Call Girls Near Me',
    link: '/call-girl-near-me',
    subMenu: [
      {
        title: `Call Girl Sites`,
        link: `call-girl-sites`,
      },
      {
        title: `Top Rated Call Girl`,
        link: `top-rated-call-girl`,
      },
    ],
  },
  { title: 'Blogs', link: '/blogs' },
  {
    title: 'Other Pages',
    link: '#',
    subMenu: [
      {
        title: `About`,
        link: `/about-us`,
      },
      {
        title: `Contact Us`,
        link: `/contact-us`,
      },
      {
        title: `Terms & Condition`,
        link: `/terms-and-condition`,
      },
      {
        title: `Privacy Policy`,
        link: `/privacy-policy`,
      },
    ],
  },
];
