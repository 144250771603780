import React, { memo, useCallback, CSSProperties } from 'react';
import { useRouter } from 'next/router';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import dynamic from 'next/dynamic';

import cityData from '@/cityData';
import settings from '@/settings.js';
import { QUERY_DATA } from '@/types/interfaces';
import { capitalize } from '@/utils/helpers';

// Dynamically import Select component
const Select = dynamic(() => import('react-select'), { ssr: true });

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const getTitle = (asPath: string): string => {
  if (asPath.includes('fbclid')) {
    return 'Discover the Best Call Girls in India';
  }
  if (asPath.includes('city')) {
    const [key, value] = asPath.split('=');
    if (value) {
      return `Discover the Best Call Girls in ${capitalize(value)}, Near Me`;
    } else {
      return `Discover the Best Call Girls in India`;
    }
  }
  const cityName = asPath.substring(asPath.lastIndexOf('/') + 1, asPath.length);
  const neverArray = ['call-girl-near-me', 'top-rated-call-girl'];
  switch (asPath) {
    case '/call-girl-sites':
    case '/':
      return 'Discover the Best Call Girls in India';
    case '/escorts':
      return 'Discover the Best Call Girls in India, Near Me';
    default:
      return !neverArray.includes(cityName)
        ? `Discover the Best Call Girls in ${capitalize(cityName)}, Near Me`
        : 'Discover the Best Call Girls Near Me';
  }
};
const SearchPage = (queryData: QUERY_DATA) => {
  const router = useRouter();
  const getStateWiseCity = useCallback((state: any) => {
    // if (!state || !state.slug) return [];

    const allState = cityData['all-regions'].map(qa => ({
      value: qa.code,
      label: qa.desc,
      region: qa.region,
    }));

    const options = [];
    if (state && state?.slug) {
      const getCityWiseData = cityData[state.slug].map(qa => ({
        value: qa.code,
        label: qa.desc,
      }));

      options.push({
        label: 'All the City of region',
        options: getCityWiseData,
      });
    }

    options.push({
      label: 'Main All City',
      options: allState,
    });

    return options;
  }, []);

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  const title = getTitle(router.asPath);

  return (
    <section className="search-sec">
      <div className="banner-bg">
        <h1>{title}</h1>
        <img src="../assets/img/couple-bg.webp" alt="Best Adult Website" />
      </div>
      <div className="container">
        <div className="inner-search">
          <Formik
            initialValues={{
              category: queryData?.category || 'escorts',
              search: queryData?.search || '',
              state: queryData?.state || { name: '', slug: '' },
              city: queryData?.city ? { label: '', value: queryData.city } : { label: '', value: '' },
            }}
            validateOnMount
            validateOnChange
            validateOnBlur
            onSubmit={values => {
              let url = '';
              const slash = '/';

              if (values.category) {
                url += slash + values.category;
              }

              if (values.city && values.city.value) {
                if (url !== '') {
                  url += slash;
                }
                url += values.city.value;
              } else {
                if (values.state && values.state.slug) {
                  if (url !== '') {
                    url += slash;
                  }
                  url += values.state.slug;
                }
              }

              if (url === '') {
                url = slash;
              }

              router.push({
                pathname: url,
              });
            }}
          >
            {({ handleSubmit, handleReset, handleChange, values, setFieldValue }) => (
              <Form noValidate onSubmit={handleSubmit} onReset={handleReset} className="customForm">
                <Row>
                  <Col md={4}>
                    <div className="form-group">
                      <select onChange={handleChange} name="category" value={values.category} className="form-control">
                        <option value="escorts">Escorts ( Call Girls )</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group">
                      <Select
                        name="state"
                        classNamePrefix="selectState"
                        options={settings.state}
                        onChange={e => {
                          setFieldValue('state', e);
                          setFieldValue('city', {});
                        }}
                        value={values.state}
                        isSearchable
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.slug}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group">
                      <Select
                        name="city"
                        options={getStateWiseCity(values.state)}
                        onChange={e => setFieldValue('city', e)}
                        value={values.city}
                        isSearchable
                        formatGroupLabel={formatGroupLabel}
                      />
                    </div>
                  </Col>
                  <div className="col-md-2">
                    <div className="search-now-btn">
                      <button type="submit" className="btn btn_primary">
                        Search Now
                      </button>
                    </div>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default memo(SearchPage);
